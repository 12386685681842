<template>
  <section>
    <breadcrumb :title="$t('agents')" :subtitle="$t('list_agent')"/>
    <div class="card px-10 pb-30 rounded-10">
      <div class="columns filters">
        <div class="column is-flex is-align-items-center">
          <div>
            <b-field>
              <b-input :placeholder="$t('search')"
                       custom-class="search-filter"
                       v-model="search"
                       type="search"
                       icon="magnify"
                       icon-clickable
                       @icon-click="searchIconClick"
              >
              </b-input>
            </b-field>
          </div>
          <div class="ml-15">
            <b-icon type="is-pink-light" size="is-medium" icon="close-circle-outline"
                    class="mx-4 cursor-pointer"></b-icon>
          </div>
          <div @click="filter=!filter" class="ml-25 cursor-pointer">
            <b-icon type="is-purple" size="is-medium" :icon="filter_name"></b-icon>
          </div>
        </div>
        <div @click="goTo('formAgent')" class="cursor-pointer">
          <b-icon type="is-green-success" icon="account-plus" size="is-medium"></b-icon>
        </div>
      </div>
      <template v-if="filter">
        <div class="columns">
          <div class="column is-6">
            <div class="is-flex is-justify-content-flex-start">
              <b-field>
                <b-input :placeholder="$t('name')"
                         v-model="query.name"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
              <b-field class="mx-10">
                <b-input :placeholder="$t('agent')"
                         v-model="query.agent"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="column is-6">
            <div class="is-flex is-justify-content-flex-start">
              <b-field>
                <b-input :placeholder="$t('state')"
                         v-model="query.state"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
              <b-field class="mx-10">
                <b-input :placeholder="$t('payment_groups')"
                         v-model="query.pg"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column is-6">
            <div class="is-flex is-justify-content-flex-start">
              <b-field>
                <b-input :placeholder="$t('agent_type')"
                         v-model="query.agentType"
                         custom-class="custom-search"
                         icon-clickable
                >
                </b-input>
              </b-field>
            </div>
          </div>
          <div class="column is-6">
            <div class="is-flex is-justify-content-flex-end">
              <b-button @click="getQueryFilter" class="btn btn-search" type="is-primary">
                {{ $t('search') }}
              </b-button>
            </div>
          </div>
        </div>
      </template>
    </div>

    <section class="pt-40">
      <b-table
          :data="desserts.data"
          ref="table"
          :paginated="false"
          detailed
          :show-detail-icon="false"
          detail-key="email"
          :per-page="desserts.itemsPerPage"
          aria-next-label="Next page"
          aria-previous-label="Previous page"
          aria-page-label="Page"
          aria-current-label="Current page">

        <b-table-column field="name" :label="$t('name')" v-slot="props">
          <template>
            <a class="col name" @click="props.toggleDetails(props.row)">
              {{ props.row.name }}
            </a>
          </template>
        </b-table-column>

        <b-table-column field="active" :label="$t('active')" v-slot="props">
          <div class="data-check">
            <b-checkbox type="is-success" v-model="props.row.isActive"></b-checkbox>
          </div>
        </b-table-column>

        <b-table-column field="agency" :label="$t('agency')" v-slot="props">
        <span class="col agency">
          {{ props.row.agencyName }}
        </span>
        </b-table-column>
        <b-table-column v-if="false" field="phone" :label="$t('exchange_rate')" v-slot="props">
          <div @click="setPropsModalsExchangeRate(props.row)" class="cursor-pointer">
            <b-tooltip :label="$t('edit')">
              <b-tag type="is-green-success" rounded>
                <span class="text-status is-uppercase"> 1x{{ props.row.exchangeRate }}</span>
              </b-tag>
            </b-tooltip>
          </div>
        </b-table-column>

        <b-table-column field="agent_type" :label="$t('agent_type')" v-slot="props">
       <span class="agent_type">
          <b-tag v-if="props.row.agentType === agentType.EXTERNAL" type="is-tag-success success" rounded>
          <span class="text-status external is-uppercase"> {{ $t('external_agent') }}</span>
          </b-tag>
          <span v-else class="tag  purple rounded-10">
          <span class="text-status internal is-uppercase"> {{ $t('internal_agent') }}</span>
          </span>
       </span>
        </b-table-column>

        <b-table-column field="payment_groups" :label="$t('utility_agents')" v-slot="props">
        <span class="col payment-groups" v-for="(item, idx) in props.row.paymentGroups"
              :key="idx"
        >
          <li>{{ item.currencyTo }} - ( <span
              :class="{'color-red': item.agencyCommission.rate < 0}">{{ item.agencyCommission.rate }}%</span>)</li>
        </span>
        </b-table-column>

        <b-table-column :label="$t('actions')" v-slot="props">
          <div class="is-flex">
            <router-link :to="{name:'formEditAgent', params: {id: props.row.id}}">
              <div class="mx-10">
                <b-icon type="is-green-accent" icon="pencil"
                        class="mx-4 cursor-pointer"></b-icon>
              </div>
            </router-link>
            <div @click="props.toggleDetails(props.row)" class="mx-10">
              <b-icon type="is-primary" icon="launch" custom-class="mdi-rotate-90 cursor-pointer" class="mx-4"></b-icon>
            </div>
            <div class="mx-10" @click="confirmCustomDelete(props.row.id)">
              <b-icon type="is-danger" icon="trash-can-outline" class="mx-4 cursor-pointer"></b-icon>
            </div>
          </div>
        </b-table-column>
        <template #detail="props">
          <section>
            <div class="columns">
              <div class="column is-9">
                <div class="is-flex">
                  <div class="is-flex is-flex-direction-column">
                    <span class="label-data">{{ $t('email') }}</span>
                    <span class="label-data">{{ $t('phone') }}</span>
                    <span class="label-data">{{ $t('address') }}</span>
                    <span class="label-data" v-if="props.row.paymentGroups.length">{{ $t('payment_groups') }}</span>

                  </div>
                  <div class="is-flex is-flex-direction-column ml-40">
                    <span class="item">{{ props.row.email }}</span>
                    <span class="item">{{ props.row.phoneNumber }}</span>
                    <span class="item">{{ props.row.addressLine }}</span>
                    <span class="item payment-groups" v-for="(value, idx) in props.row.paymentGroups"
                          :key="idx">
                    <li>{{ value.currencyTo }}:{{ value.amount }}</li>
                  </span>
                  </div>
                </div>
              </div>
              <div class="column is-3">
                <div class="is-flex">
                  <div class="is-flex is-flex-direction-column ml-40">
                    <span class="label-data">{{ $t('created') }}</span>
                    <span class="label-data">{{ $t('updated') }}</span>
                  </div>
                  <div class="is-flex is-flex-direction-column ml-40">
                    <span class="item">{{ props.row.created }}</span>
                    <span class="item">{{ props.row.updated }}</span>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </template>
      </b-table>
      <paginate :count="desserts.totalRecords"
                :per_page="desserts.itemsPerPage"
                :current-page="desserts.currentPage"
                :total-pages="desserts.totalPages"
                @first="getLast(true)"
                @previous="getAgents(desserts.currentPage - 1,desserts.itemsPerPage)"
                @next="getAgents(desserts.currentPage + 1,desserts.itemsPerPage)"
                @last="getLast"
                @perPage="getAgents(desserts.currentPage,$event)"
                @search="getAgents($event,desserts.itemsPerPage)"
      />
      <b-modal
          v-model="isModalEditAgentActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          aria-modal>
        <template #default="props">
          <modal-add-agent :item="propsModals" v-bind="propsModals" @close="props.close"></modal-add-agent>
        </template>
      </b-modal>
      <b-modal
          v-model="isModalDataAgentEditExchangeRateActive"
          has-modal-card
          trap-focus
          :destroy-on-hide="false"
          aria-role="dialog"
          aria-label="Example Modal"
          aria-modal>
        <template #default="props">
          <modals-edit-exchange-rate :item="propsModalsExchangeRate"
                                     @reload="getAgents(desserts.currentPage,desserts.itemsPerPage)"
                                     v-bind="propsModalsExchangeRate" @close="props.close"></modals-edit-exchange-rate>
        </template>
      </b-modal>
    </section>
  </section>
</template>

<script>
import Breadcrumb from "@/components/structure/breadcrumb";
import ModalAddAgent from "@/components/modals/modalAddAgent";
import Paginate from "@/components/list/paginate";
import {AGENT_TYPE} from "@/enum/agentType";
import {mapActions, mapGetters} from "vuex";
import ModalsEditExchangeRate from "@/components/modals/modalsEditExcahngeRate";
import {notificationSuccess} from "@/utils";

export default {
  name: "ListAgentComponent",
  components: {ModalsEditExchangeRate, Paginate, ModalAddAgent, Breadcrumb},
  data() {
    return {
      search: null,
      filter_name: 'filter-outline',
      filter: false,
      isModalEditAgentActive: false,
      isModalDataAgentEditExchangeRateActive: false,
      propsModals: {},
      propsModalsExchangeRate: {},
      itemsPerPage: 0,
      currentPage: 0,
      totalPages: 0,
      totalRecords: 0,
      agentType: AGENT_TYPE,
      query: {
        name: null,
        email: null,
        phone: null,
        state: null,
        pg: null,
        agent: null,
        agentType: null
      }
    }
  },
  methods: {
    ...mapActions({
      fetchAgents: 'FETCH_AGENT',
      goTo: 'GO_TO',
      deleteUser: 'DELETE_USER',
    }),
    setPropsModalsExchangeRate(newProp) {
      this.propsModalsExchangeRate = newProp
      this.isModalDataAgentEditExchangeRateActive = true
    },
    setPropsModals(newProp) {
      this.propsModals = newProp
      this.isModalEditAgentActive = true
    },
    getLast(isFirst = false) {
      let page = isFirst ? 1 : this.desserts.totalPages
      this.getAgents(page, this.desserts.itemsPerPage)
    },
    getAgents(current = 1, record = 10, id = null, query = null) {
      let data = {
        currentPage: current,
        recordsPerPage: record,
        id: id,
        query: query
      };
      this.fetchAgents(data)
    },
    reload() {
      window.location.reload()
    },
    getQueryFilter() {
      let query = ''
      let prefix = '&'
      if (this.query.name) {
        query += `${prefix}name=${this.query.name}`
      }
      if (this.query.email) {
        query += `${prefix}email=${this.query.email}`
      }
      if (this.query.phone) {
        query += `${prefix}phonenumber=${this.query.phone}`
      }
      if (this.query.agent) {
        query += `${prefix}agentName=${this.query.agent}`
      }
      if (this.query.agentType) {
        let val = 1
        if (this.query.agentType.toLowerCase() === 'interno' || this.query.agentType.toLowerCase() === 'internal') {
          val = 0
        }
        if (this.query.agentType.toLowerCase() === 'externo' || this.query.agentType.toLowerCase() === 'external') {
          val = 1
        }
        query += `${prefix}AgentType=${val}`
      }
      if (this.query.state) {
        query += `${prefix}state=${this.query.state}`
      }
      if (this.query.pg) {
        query += `${prefix}paymentGroupName=${this.query.pg}`
      }
      if (query.length) {
        this.getAgents(1, 10, null, query)
      } else {
        this.getAgents(1, 10, null, null)
      }
    },
    searchIconClick() {
      let query = this.search ? `&name=${this.search}` : null
      this.getAgents(1, 10, null, query)

    },
    confirmCustomDelete(id) {
      this.$buefy.dialog.confirm({
        title: 'Eliminando Agente',
        message: 'Esta seguro que desea <b>eliminar</b> el agente?',
        confirmText: 'Comfirmar',
        type: 'is-danger',
        hasIcon: true,
        onConfirm: () => {
          this.deleteUser(id)
              .then(() => {
                notificationSuccess('Agente eliminado');
                this.getAgents(1, 10)
              })
        }
      })
    }
  },
  computed: {
    ...mapGetters({
      desserts: 'GET_AGENT_LIST'
    })
  },
  watch: {
    search: function () {
      if (this.search.length === 0) {
        this.searchIconClick()
      }
    },
    filter: function () {
      this.filter_name = this.filter ? 'filter-remove-outline' : 'filter-outline'
    }
  },
  created() {
    this.getAgents(1, 10)
  }
}
</script>

<style scoped lang="scss">
.col {
  line-height: 32px;
  letter-spacing: 0.5px;

  &.name {
    font-weight: bold;
    font-size: 16px;
    color: #666BAA;
  }


  &.payment-groups {
    font-weight: 800;
    font-size: 15px;
    line-height: 20px;
    color: #289BA4;
  }

  &.agency {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #0B2B46;
  }

}

.text-status {
  line-height: 32px;
  text-align: center;
  letter-spacing: 0.5px;
  font-weight: bold;

  &.external {
    color: #289BA4;
  }

  &.internal {
    color: #4D5EC3;
  }


}

.purple {
  background: #dbdffa;
}

.label-data {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #000000;
}

.item {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 32px;
  letter-spacing: 0.5px;
  color: #0B2B46;

}
</style>