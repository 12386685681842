<template>
  <div class="modal-card modal-form rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal">{{ $t('edit_exchange_rate') }}</div>
          <button @click="closed" class="delete" aria-label="close"></button>
        </div>
        <section class="pl-20 pr-10 pt-15">
          <div class="columns is-flex is-justify-content-space-between is-align-items-center">
            <div class="column is-half">
              <b-field :label="`${$t('exchange_rate')}*`">
                <b-input v-model="exchangeRate" type="text" custom-class="input-code"></b-input>
              </b-field>
            </div>
            <div class="column is-2">
              <button @click="save" class="button save is-primary mt-30">{{ $t('save') }}</button>
            </div>
          </div>
        </section>
      </div>
    </section>
  </div>

</template>

<script>
import {mapActions} from "vuex";
import {USER_TYPE} from "@/enum/userType";

export default {
  name: "modalsEditExchangeRate",
  data() {
    return {
      exchangeRate: null,
    }
  },
  props: ['item'],
  methods: {
    ...mapActions({
      editExchangeRateAgency: 'EDIT_AGENCY_EXCHANGE_RATE',
      editExchangeRateAgent: 'EDIT_AGENT_EXCHANGE_RATE'
    }),
    save() {
      let edit = {
        exchangeRate: Number(this.exchangeRate),
        id: this.item.id
      }
      if (this.item.userType === USER_TYPE.AGENCY) {
        this.editExchangeRateAgency(edit)
            .then(() => {
              this.closed()
            })
      } else if (this.item.userType === USER_TYPE.AGENT) {
        this.editExchangeRateAgent(edit)
            .then(() => {
              this.closed()
            })
      }

    },
    closed() {
      this.$emit('close')
      this.$emit('reload')
      window.location.reload()
    }
  },
  created() {
    this.exchangeRate = this.item.exchangeRate
  }
}
</script>

<style scoped lang="scss">

</style>