<template>
  <div class="modal-card modal-form rounded-10">
    <section class="modal-card-body">
      <div class="content">
        <div class="is-flex is-justify-content-space-between is-align-items-center">
          <div class="title-new-modal">{{ $t('new_agent') }}</div>
          <button @click="$emit('close')" class="delete" aria-label="close"></button>
        </div>
        <section>
          <form-wizard color="#666BAA"
                       stepSize="xs"
                       title=""
                       subtitle=""
                       :nextButtonText="$t('next')"
                       :backButtonText="$t('back_btn')"
                       :finishButtonText="$t('save')"
                       @on-complete="onComplete">
            <tab-content :title="$t('data')"
                         icon="ti-user">
              <section class="pt-10 pb-20">
                <b-field :label="`${$t('name')}*`" custom-class="custom-field-label">
                  <b-input custom-class="full-input" type="text" placeholder=""></b-input>
                </b-field>
                <b-field :label="`${$t('phone')}*`" custom-class="custom-field-label">
                  <b-input custom-class="full-input" type="text" placeholder=""></b-input>
                </b-field>
                <b-field :label="`${$t('email')}*`" custom-class="custom-field-label">
                  <b-input custom-class="full-input" type="email" placeholder=""></b-input>
                </b-field>
                <b-field :label="`${$t('agency')}*`" custom-class="custom-field-label">
                  <b-select expanded>
                    <option></option>
                  </b-select>
                </b-field>
                <b-field :label="`${$t('payment_groups')}*`" custom-class="custom-field-label">
                  <b-select expanded>
                    <option></option>
                  </b-select>
                </b-field>
                <b-field :label="`${$t('agent_type')}*`" custom-class="custom-field-label">
                  <b-select expanded>
                    <option></option>
                  </b-select>
                </b-field>
                <div class="is-flex is-justify-content-flex-end mt-20">
                  <b-field>
                    <b-switch :value="true"
                              :left-label="true"
                              size="is-medium"
                              type="is-green-success">
                      {{ $t('active') }}
                    </b-switch>
                  </b-field>
                </div>
              </section>
            </tab-content>
            <tab-content :title="$t('localization')"
                         icon="ti-settings">
              <section class="pt-10 pb-20">
                <div class="columns">
                  <div class="column">
                    <b-field :label="`${$t('address_number', { number: 1 })}*`" custom-class="custom-field-label">
                      <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                    </b-field>
                    <b-field :label="$t('address_number', { number: 2 })" custom-class="custom-field-label">
                      <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                    </b-field>
                    <b-field :label="$t('address_number', { number: 3 })" custom-class="custom-field-label">
                      <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                    </b-field>

                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <div class="columns">
                      <div class="column is-half">
                        <b-field :label="`${$t('city')}*`" custom-class="custom-field-label">
                          <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                        </b-field>
                        <b-field :label="`${$t('zip')}*`" custom-class="custom-field-label">
                          <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                        </b-field>
                      </div>
                      <div class="column is-half">
                        <b-field :label="`${$t('state')}*`" custom-class="custom-field-label">
                          <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                        </b-field>
                        <b-field :label="`${$t('country')}*`" custom-class="custom-field-label">
                          <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                        </b-field>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

            </tab-content>
            <tab-content :title="$t('credentials')"
                         icon="ti-check">
              <section class="pt-10 pb-20">
                <div class="columns is-align-items-center is-flex">
                  <div class="column is-4 is-align-items-center is-flex-direction-column">
                    <div class="rounded-50x avatar">
                      <span class="mdi mdi-account-circle account-avatar"></span>
                    </div>
                    <avatar/>
                  </div>
                  <div class="column is-4 is-flex is-flex-direction-column">
                    <span class="help">{{ $t('avatar') }}</span>
                    <span class="help">{{ $t('avatar_help') }}</span>
                  </div>
                </div>
                <div class="columns">
                  <div class="column">
                    <b-field :label="`${$t('username')}*`" custom-class="custom-field-label">
                      <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                    </b-field>
                    <b-field :label="`${$t('password')}*`" custom-class="custom-field-label">
                      <b-input type="text" placeholder="" custom-class="full-input"></b-input>
                    </b-field>
                  </div>
                </div>
              </section>
            </tab-content>
          </form-wizard>
        </section>
      </div>
    </section>
  </div>
</template>

<script>
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'

export default {
  name: "modalAddAgent",
  components: {
    FormWizard,
    TabContent
  },
  methods: {
    onComplete() {
    }
  }
}
</script>

<style lang="scss">
.custom-field-label {
  font-style: normal !important;
  font-weight: bold !important;
  font-size: 15px !important;
  line-height: 32px !important;
  letter-spacing: 0.5px !important;
  color: #AEAEAE !important;
}

.help {
  font-style: normal;
  font-weight: 800;
  font-size: 15px;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #C4C4C4;
}

.avatar {
  height: 156px;
  width: 156px;
  background: #DEDDDD;
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  margin-right: 0 !important;
}

.account-avatar {
  font-size: 100px;
}
</style>